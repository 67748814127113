/**
 * Handy hook to show toasts in the UI if the user's connection to our servers is lost.
 * This could be due to:
 * - Disconnecting from WiFi network
 */
import { selectConnectionState } from 'modules/connection/reducer'
import { useFeatureFlag } from 'modules/featureFlags'
import { useAppSelector } from 'modules/redux'

export function useHealthCheck() {
  const offlineTest = useFeatureFlag('offlineTest')
  const { online } = useAppSelector(selectConnectionState)
  if (offlineTest) {
    return { isConnected: false }
  }

  return { isConnected: online }
}
