/**
 * Handles Google logins and signups only.
 * Does NOT handle user/password logins and signups.
 */
import { config } from 'config'
import {
  getApolloClient,
  UpdateUserDocument,
  UpdateUserSettingsDocument,
} from 'modules/api'
import { sendGTMSignupCompleteEvent } from 'modules/googleTagManager/events'
import { replaceState } from 'modules/history'
import {
  getSupportedLocaleKeyFromNavigatorLanguageOrUseFallback,
  isNavigatorLanguageGA,
} from 'modules/i18n/utils/localeKey'
import { getUserMetadataStore } from 'modules/performance/UserMetadataStore'
import { analytics, SegmentEvents } from 'modules/segment'
import { generateSettingsUpdatePatch } from 'modules/user/utils'
import { localStore } from 'utils/storage'
import { USER_SETTINGS_CONSTANTS } from 'utils/userSettingsConstants'

import { getDefaultSignupRedirect } from './utils'

type SignupData = {
  isFirstWorkspace: boolean // A user just signed up, and this is that user's FIRST workspace
  newWorkspace: boolean // A user just signed up, and a new workspace was created
  signupFlow: string
  welcomeDocId?: string
  userId: string
  workspaceId: string
}

const skipTour = (signupFlow: string) => {
  // Skip tour and welcome if user comes through doc invitation or access link
  return ['docAccessLink', 'docInvitation'].includes(signupFlow)
}

const getDefaultRedirectForFirstWorkspace = ({
  data,
  redirectTo,
}: {
  data?: SignupData
  redirectTo?: string
}) => {
  let defaultRedirect = redirectTo ? redirectTo : getDefaultSignupRedirect()

  if (data) {
    const { welcomeDocId, newWorkspace, signupFlow } = data

    if (welcomeDocId) {
      // The welcomeDocId is not persisted on the BE, so store it in local
      // storage here for possible use on initial sign up
      try {
        localStore.setItem(
          USER_SETTINGS_CONSTANTS.welcomeDocId,
          JSON.stringify(welcomeDocId)
        )
      } catch (err) {
        // Owell, we tried
      }
    }

    if (skipTour(signupFlow)) {
      return defaultRedirect
    }

    // This is a new workspace, and we should give them the chance to set the workspace name
    if (newWorkspace) {
      defaultRedirect = '/welcome?newWorkspace=true'
    }
  }
  return defaultRedirect
}

const identifyLoggedinUser = (data?: SignupData) => {
  if (!data) return
  const { userId, workspaceId } = data
  analytics.identify(userId, {
    user_org_id: workspaceId,
    anonymous_user: false,
    gamma_user_id: userId,
  })
}

const setUserReferrer = (id: string, referrer: string) => {
  return getApolloClient().mutate({
    mutation: UpdateUserDocument,
    variables: {
      input: {
        id,
        referrer,
      },
    },
  })
}

const setUserLocale = () => {
  const locale = getSupportedLocaleKeyFromNavigatorLanguageOrUseFallback()
  const settings = {
    set: {
      locale,
    },
  }
  const patch = generateSettingsUpdatePatch(settings)

  return getApolloClient().mutate({
    mutation: UpdateUserSettingsDocument,
    variables: {
      patch,
    },
  })
}

export const handleLoginComplete = ({
  redirectTo,
  data,
  doneCallback,
  useReplaceState = true,
}: {
  redirectTo?: string
  data?: SignupData
  doneCallback?: () => void
  // If true, the window.location.href will be set to the redirectTo URL
  useReplaceState?: boolean
}) => {
  const userMetadataStore = getUserMetadataStore()
  const context = userMetadataStore.get()

  let defaultRedirect = redirectTo ? redirectTo : '/'

  identifyLoggedinUser(data)

  analytics.track(
    SegmentEvents.LOGIN,
    {
      provider: 'google',
    },
    { context }
  )
  if (!redirectTo && doneCallback) {
    doneCallback()
    return
  }

  // Handle the scenario where an orgless user joins a workspace for the first time
  const isFirstWorkspaceForUser = data?.isFirstWorkspace
  if (isFirstWorkspaceForUser && data) {
    defaultRedirect = getDefaultRedirectForFirstWorkspace({
      data: data,
      redirectTo,
    })
  }

  if (useReplaceState) {
    replaceState({ pathname: defaultRedirect })
  } else {
    window.location.href = defaultRedirect
  }
}

export const handleSignupComplete = ({
  data,
  doneCallback,
  redirectTo,
  useReplaceState = true,
}: {
  data?: SignupData
  doneCallback?: () => void
  redirectTo?: string
  useReplaceState?: boolean
}) => {
  const userMetadataStore = getUserMetadataStore()
  const context = userMetadataStore.get()
  identifyLoggedinUser(data)

  const trackPromise = new Promise((resolve) => {
    analytics.track(
      SegmentEvents.SIGNUP,
      {
        flow: data?.signupFlow,
        provider: 'google',
      },
      { context },
      resolve
    )
  })

  const userId = data?.userId
  const setReferrerPromise =
    userId && config.IS_USER_TESTING_SESSION
      ? setUserReferrer(userId, 'USER_TESTING')
      : Promise.resolve()

  if (userId && isNavigatorLanguageGA()) {
    /// Only set locale override if it's a GA language
    setUserLocale()
  }

  let defaultRedirect = redirectTo ? redirectTo : '/'

  const isFirstWorkspaceForUser = data?.isFirstWorkspace
  // If a user already has a workspace, and is joining another one, or,
  // if for some reason we explicitly set `isFirstWorkspace` to false in
  // signup-flow.ts, as in the case of `DocInvitationSignupFlow` and
  // `DocAccessLinkSignupFlow`, then we never want to direct them to /?getStarted=true
  if (!redirectTo && doneCallback && !isFirstWorkspaceForUser) {
    doneCallback()
    return
  }

  if (isFirstWorkspaceForUser && data) {
    sendGTMSignupCompleteEvent()
    const { signupFlow } = data
    if (skipTour(signupFlow) && doneCallback) {
      doneCallback()
      return
    }
    defaultRedirect = getDefaultRedirectForFirstWorkspace({
      data: data,
      redirectTo,
    })
  }

  Promise.all([setReferrerPromise, trackPromise]).then(() => {
    if (useReplaceState) {
      replaceState({ pathname: defaultRedirect })
    } else {
      window.location.href = defaultRedirect
    }
  })
}
