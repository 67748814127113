import { useEffect, useRef } from 'react'
import * as workerTimers from 'worker-timers'

import { useAppDispatch, useAppSelector, useAppStore } from 'modules/redux'
import { apiFetchUser } from 'modules/user/api/fetchUser'

import {
  ConnectionEventEmitter,
  ConnectionEvents,
} from '../ConnectionEventEmitter'
import { selectConnectionState, updateConnectionState } from '../reducer'

export const useEmitNavigatorOnlineEvents = () => {
  const store = useAppStore()

  // load the initial state into redux
  useEffect(() => {
    const initialOnline = window.navigator.onLine
    // load initial state into redux
    store.dispatch(
      updateConnectionState({
        navigatorOnline: initialOnline,
        online: initialOnline,
      })
    )
  }, [store])

  // handle syncing navigator.onLine with redux
  useEffect(() => {
    const onlineHandler = () => {
      const isOnline = window.navigator.onLine

      store.dispatch(
        updateConnectionState({
          navigatorOnline: isOnline,
        })
      )

      if (isOnline) {
        ConnectionEventEmitter.emit('navigatorOnline', {})
      } else {
        ConnectionEventEmitter.emit('navigatorOffline', {})
      }
    }

    window.addEventListener('online', onlineHandler)
    window.addEventListener('offline', onlineHandler)
    return () => {
      window.removeEventListener('online', onlineHandler)
      window.removeEventListener('offline', onlineHandler)
    }
  }, [store])
}
/**
 * This function represents our test to check if the user is online and
 * able to connect to our servers.
 *
 * We make sure that we don't retry the apiFetchUser call and handle
 * the polling mechanisms ourself in these hooks.
 *
 * The apiFetchUser function also includes logic for handling the fetching
 * the offline user, this function always wants the network to be called
 */
const testUserOnline = async (): Promise<boolean> => {
  if (!window.navigator.onLine) {
    return false
  }

  try {
    const response = await apiFetchUser({
      maxRetries: 0,
      returnOfflineUser: false,
    })

    return response.status !== 'error'
  } catch (e) {
    return false
  }
}
const wait = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

/**
 * Compute the interval duration for each next reconnect attempt
 * at first start quikcly after we've reached the end of the array
 * just use the last value
 */
const getReconnectTimeout = (attempt: number) => {
  const RECONNECT_INTERVAL = [1000, 3000, 5000, 15000]
  const timeoutInd = Math.min(attempt, RECONNECT_INTERVAL.length - 1)

  return RECONNECT_INTERVAL[timeoutInd]
}

/**
 * This hook handles determining if we're online or offline.  This is used
 * for determining to show "Not connected" components and the little cloud
 * in the ui
 */
const useOnlineMonitor = () => {
  const dispatch = useAppDispatch()
  const { navigatorOnline, online } = useAppSelector(selectConnectionState)

  const reconnectTimeoutId = useRef<ReturnType<typeof setTimeout> | null>(null)

  // This useEffect sets up reconnection logic when we detect the navgiator is offline
  // This could be changed to run when we "think" we're offline, right now
  // we're only relying on the naviagtor.onLine property
  useEffect(() => {
    if (navigatorOnline) {
      // we dont care
      return
    }
    // if navigator is offline then we know connection state is offline as well
    dispatch(updateConnectionState({ online: false }))
    if (reconnectTimeoutId.current) {
      // if we have a timeout in progress just return
      return
    }

    const attemptReconnect = async (attempt: number = 0) => {
      // if we call this while a timeout is in progress, cancel the current
      // timeout and start a new one
      if (reconnectTimeoutId.current) {
        clearTimeout(reconnectTimeoutId.current)
        reconnectTimeoutId.current = null
      }

      const isOnline = await testUserOnline()

      if (isOnline) {
        // we've come back online clean everything up
        dispatch(updateConnectionState({ online: true }))
      } else {
        // start the timeout again
        const timeout = getReconnectTimeout(attempt)
        reconnectTimeoutId.current = setTimeout(() => {
          attemptReconnect(attempt + 1)
        }, timeout)
      }
    }

    wait(getReconnectTimeout(0)).then(attemptReconnect)

    // when the navigator comes online do a check immediately
    return ConnectionEventEmitter.on('navigatorOnline', () => {
      attemptReconnect(0)
    })
  }, [navigatorOnline, dispatch])

  // forward online redux state to the ConnectionEventEmitter
  // In general it's okay for this to be in a useEffect, since notifying
  // the events are the last thing to happen
  const timeOfflineRef = useRef<number | null>(null)

  useEffect(() => {
    // only emit online if we've emitted the offline event before
    if (online && timeOfflineRef.current) {
      console.log('[Offline] user is back online')
      ConnectionEventEmitter.emit('online', {
        timeOffline: Date.now() - timeOfflineRef.current,
      })
      timeOfflineRef.current = null
    } else if (!online) {
      timeOfflineRef.current = Date.now()
      ConnectionEventEmitter.emit('offline', {})
    }
  }, [online])
}

/**
 * This hook handles the logic for when the user is in the background
 */
const useEmitBackgroundEvents = () => {
  const store = useAppStore()
  const backgroundTimeRef = useRef<number | null>(null)

  useEffect(() => {
    const backgroundHandler = () => {
      const documentIsHidden = document.hidden
      store.dispatch(
        updateConnectionState({
          backgrounded: documentIsHidden,
        })
      )

      if (documentIsHidden) {
        backgroundTimeRef.current = Date.now()
        ConnectionEventEmitter.emit('background', {
          backgroundTime: Date.now(),
        })
      } else {
        let timeInBackground: number | null = null
        if (backgroundTimeRef.current) {
          timeInBackground = Date.now() - backgroundTimeRef.current
        }

        ConnectionEventEmitter.emit('foreground', {
          timeInBackground,
        })
      }
    }

    document.addEventListener('visibilitychange', backgroundHandler)
    return () => {
      document.removeEventListener('visibilitychange', backgroundHandler)
    }
  }, [store])
}

const WAKEUP_INTERVAL_PERIOD = 1000
const WAKEUP_SKEW_FACTOR = 30

// Use a 30s skew time. Note that during heavy computations the skew
// of an active browser actually gets into the 5-10s range
const MINIMUM_SKEW_TIME = WAKEUP_INTERVAL_PERIOD * WAKEUP_SKEW_FACTOR

/**
 * Hook to call a Callback whenever we "wake up".
 *
 * Detect waking up from sleeping by computing the difference between
 * where we expect the clock to be and where it actually is (skew), leaving
 * some wiggle room of a couple seconds to account for imperfect javascript timing
 */
export const useEmitWakeupEvents = () => {
  // Setup intervals to refresh the session cookie and detect "waking up"
  useEffect(() => {
    let lastKnownTime = Date.now()

    const wakeupDetectionInterval = workerTimers.setInterval(() => {
      const currentTime = Date.now()
      if (
        currentTime >
        lastKnownTime + WAKEUP_INTERVAL_PERIOD + MINIMUM_SKEW_TIME
      ) {
        ConnectionEventEmitter.emit('wakeup', {})
      }
      lastKnownTime = currentTime
    }, WAKEUP_INTERVAL_PERIOD)

    return () => {
      workerTimers.clearInterval(wakeupDetectionInterval)
    }
  }, [])
}

export const useEmitConnectionEvents = () => {
  useEmitBackgroundEvents()
  useEmitNavigatorOnlineEvents()
  useEmitWakeupEvents()
  useOnlineMonitor()
}

export const useOnConnectionEvent = <K extends keyof ConnectionEvents>(
  event: K,
  handler: (payload: ConnectionEvents[K]) => void
) => {
  useEffect(() => {
    ConnectionEventEmitter.on(event, handler)
    return () => ConnectionEventEmitter.off(event, handler)
  }, [event, handler])
}
