import { createSlice } from '@reduxjs/toolkit'
import { JSONContent } from '@tiptap/core'

import { RootState } from 'modules/redux'
import { AIEditOperation } from 'modules/tiptap_editor/extensions/Card/Card2/EditCardAI/AIEditOperations'

export type SuggestionVariant = {
  label: string
  id: string
  card: JSONContent // On edit, we could just update this value
  explanation?: string
  isOriginal?: boolean
}

export type CardSuggestionsStatus = 'loading' | 'done' | 'error'

export type CardSuggestions = {
  status: CardSuggestionsStatus
  interactionId?: string
  loadingMessage?: string
  selected?: string // Which variant is selected
  variants: SuggestionVariant[] // All variants
  operation?: AIEditOperation
  instructions?: string
}

export type CardSuggestionsMap = Record<
  string, // CardId
  CardSuggestions
>

export type CardSuggestionState = {
  cardSuggestionsMap: CardSuggestionsMap
  isSuggestingDocLevel: boolean
}

const initialState: CardSuggestionState = {
  cardSuggestionsMap: {},
  isSuggestingDocLevel: false,
}

const AIVariationsSlice = createSlice({
  name: 'AIVariations',
  initialState,
  reducers: {
    reset: () => initialState,
    setSuggestingDocLevel(
      state,
      action: {
        payload: boolean
      }
    ) {
      state.isSuggestingDocLevel = action.payload
    },
    setCardSuggestionsStatus(
      state,
      action: {
        payload: {
          cardId: string
          status: CardSuggestionsStatus
        }
      }
    ) {
      const { cardId, status } = action.payload
      state.cardSuggestionsMap[cardId] ??= {
        variants: [],
        status,
      }
      state.cardSuggestionsMap[cardId].status = status
    },
    setCardSuggestions(
      state,
      action: {
        payload: {
          cardId: string
          suggestions: CardSuggestions
        }
      }
    ) {
      const { cardId, suggestions } = action.payload
      state.cardSuggestionsMap[cardId] = suggestions
    },
    removeCardSuggestions(
      state,
      action: {
        payload: {
          cardId?: string
        }
      }
    ) {
      const { cardId } = action.payload
      if (cardId) {
        delete state.cardSuggestionsMap[cardId]
      } else {
        state.cardSuggestionsMap = {}
      }
    },
    addCardVariant(
      state,
      action: {
        payload: {
          cardId: string
          variant: SuggestionVariant
          autoselect?: boolean
        }
      }
    ) {
      const { cardId, variant, autoselect } = action.payload
      state.cardSuggestionsMap[cardId] ??= {
        variants: [],
        status: 'loading',
      }

      state.cardSuggestionsMap[cardId].variants.push(variant)

      if (autoselect) {
        state.cardSuggestionsMap[cardId].selected = variant.id
      }
    },
    addCardVariants(
      state,
      action: {
        payload: {
          cardId: string
          variant: SuggestionVariant
          autoselect?: boolean
        }[]
      }
    ) {
      const variants = action.payload
      variants.forEach(({ cardId, variant, autoselect }) => {
        state.cardSuggestionsMap[cardId] ??= {
          variants: [],
          status: 'done',
        }

        state.cardSuggestionsMap[cardId].variants.push(variant)

        if (autoselect) {
          state.cardSuggestionsMap[cardId].selected = variant.id
        }
      })
    },
    selectCardVariant(
      state,
      action: {
        payload: {
          cardId: string
          variantId: string
        }
      }
    ) {
      const { cardId, variantId } = action.payload
      const cardData = state.cardSuggestionsMap[cardId]
      if (cardData) {
        cardData.selected = variantId
      }
    },
  },
})

export const {
  reset,
  setSuggestingDocLevel,
  addCardVariants,
  selectCardVariant,
  setCardSuggestions,
  removeCardSuggestions,
} = AIVariationsSlice.actions

type AIVariationsSliceState = Pick<RootState, 'AIVariations'>

// Selectors

export const selectisSuggestingDocLevel = (state: AIVariationsSliceState) =>
  state.AIVariations.isSuggestingDocLevel

export const selectCardSuggestionsMap = (state: AIVariationsSliceState) =>
  state.AIVariations.cardSuggestionsMap

export const selectCardSuggestionStatusCounts = (
  state: AIVariationsSliceState
) => {
  const suggestionsMap = Object.values(selectCardSuggestionsMap(state))
  const counts = suggestionsMap.reduce(
    (acc, suggestions) => {
      acc[suggestions.status] = (acc[suggestions.status] || 0) + 1
      return acc
    },
    {
      total: suggestionsMap.length,
    } as Record<CardSuggestionsStatus, number> & { total: number }
  )

  return counts
}

export const selectCardSuggestions =
  (cardId: string) => (state: AIVariationsSliceState) =>
    state.AIVariations.cardSuggestionsMap[cardId]

export const selectCardSuggestionsStatus =
  (cardId: string) => (state: AIVariationsSliceState) =>
    state.AIVariations.cardSuggestionsMap[cardId]?.status

export const selectAllCardSuggestionsStatus = (
  state: AIVariationsSliceState
): CardSuggestionsStatus => {
  if (
    Object.values(selectCardSuggestionsMap(state)).every(
      (suggestions) => suggestions.status === 'done'
    )
  ) {
    return 'done'
  }

  if (
    Object.values(selectCardSuggestionsMap(state)).some(
      (suggestions) => suggestions.status === 'error'
    )
  ) {
    return 'error'
  }

  return 'loading'
}

export const selectSelectedCardVariant =
  (cardId: string) => (state: AIVariationsSliceState) => {
    const data = selectCardSuggestions(cardId)(state)
    if (!data) return null
    const { selected, variants } = data
    return variants.find((variant) => variant.id === selected)
  }

// Reducer
export const AIVariationsReducer = AIVariationsSlice.reducer
