import React, { useContext } from 'react'

import { ProductPrice } from 'modules/api'
import {
  GetWorkspaceSubscriptionQuery,
  useGetWorkspaceSubscriptionQuery,
} from 'modules/api/generated/graphql'
import { SegmentEvents } from 'modules/segment'

import { PaidProductKey } from '../types'

export type OpenUpsellModalArgs = {
  onOpenCallback?: () => void
  onCloseCallback?: () => void
  insufficientCreditsWarning?: Boolean
  segmentEvent: SegmentEvents
  changePlanModalOpen?: boolean
}
export type MonetizationContextType = {
  openUpsellModal: (args: OpenUpsellModalArgs) => void
  refetchSubscription?: ReturnType<
    typeof useGetWorkspaceSubscriptionQuery
  >['refetch']
  product: PaidProductKey | null
  subscription: GetWorkspaceSubscriptionQuery['subscription']
  proYearlyProductPrice: ProductPrice | undefined
}
export const MonetizationContext = React.createContext<MonetizationContextType>(
  {
    openUpsellModal: () => {},
    product: null,
    subscription: undefined,
    proYearlyProductPrice: undefined,
  }
)

export const useMonetizationContext = () => {
  const ctx = useContext(MonetizationContext)
  return ctx
}
