import { CSSObject } from '@emotion/react'

import { PPTX_EXPORTING_CLASS } from 'modules/pptx/constants'

import { Theme } from '../types'
import { getThemeHeadingColor } from '../utils/colors'

export const HEADING_CLASS = 'themed-heading'
export const HEADING_NO_GRADIENT_CLASS = 'themed-heading-no-gradient'

export const HeadingStyles: CSSObject = {
  fontWeight: 'var(--heading-font-weight)',
  fontFamily: 'var(--heading-font)',
  color: 'var(--heading-color)',
  background: 'unset',
  backgroundClip: 'unset',
  width: 'fit-content', // Keeps gradient limited to just the text width
  maxWidth: '100%', // Allows for long headings with no spaces to wrap
  letterSpacing: 'var(--letter-spacing-heading)',
  // @ts-ignore: CSS var for textTransform is not in the CSSObject type, but it's valid CSS
  textTransform: 'var(--heading-transform)',
}

export const GradientHeadingStyles = {
  ...HeadingStyles,
  background: 'var(--heading-gradient)',
  backgroundClip: 'text',
  caretColor: 'var(--heading-color)',
  color: 'transparent',
  // Make text colors stand out over gradient heading text, preventing an ugly jagged stroke
  'span.textColor': {
    textShadow:
      '0px 0.25px 0px var(--text-color), -0px -0.25px 0px var(--text-color)',
    '@media print': {
      textShadow: 'none',
    },
  },
}

export const getHeadingStylesheet = (theme: Theme): CSSObject => {
  const headingColor = getThemeHeadingColor(theme)
  const useGradient = headingColor.type === 'linear-gradient'

  if (useGradient) {
    return {
      // Use a less specific version of the selector to allow overriding it https://developer.mozilla.org/en-US/docs/Web/CSS/:where
      [`*:where(.${HEADING_CLASS})`]: GradientHeadingStyles,
      [`.${HEADING_NO_GRADIENT_CLASS}`]: {
        [`.${HEADING_CLASS}`]: HeadingStyles,
      },
      '@media print': {
        [`.${HEADING_CLASS}`]: HeadingStyles,
      },
      [`.${PPTX_EXPORTING_CLASS}`]: {
        [`.${HEADING_CLASS}`]: HeadingStyles,
      },
    }
  } else {
    return {
      [`.${HEADING_CLASS}`]: HeadingStyles,
    }
  }
}

export const resetHeadingStylesheet = (newColor?: string) => {
  return {
    [`.${HEADING_CLASS}`]: HeadingStyles,
    '--heading-color': newColor,
  }
}
