export enum AppMonitoringEvents {
  DATA_SYNC_ERROR = 'dataSyncError',
  DATA_SYNC_RECOVER = 'dataSyncRecover',
  EDITOR_SLOW_LOAD = 'editorSlowLoad',
  DUPLICATE_CARD_IDS = 'duplicateCardIds',
  EDITOR_CORE_ERROR = 'editorCoreError',

  NO_CURRENT_SNAPSHOT = 'noCurrentSnapshot',

  // This is a bot detection event, but we genericize the name
  // to make it less obvious to bots that we're detecting them
  NOT_TRUSTED_EVENTS_DETECTED = 'unknownError001',
}

// NB: If you add events here, also add them to this deck:
// https://gamma.app/docs/Event-Reference-ni0cllkqyypil0r

export enum SegmentEvents {
  // Abuse
  ABUSE_LINK_INTERCEPT_SHOWN = 'abuse.linkIntercept.shown',
  ABUSE_LINK_INTERCEPT_CONTINUE = 'abuse.linkIntercept.continue',
  ABUSE_LINK_INTERCEPT_CANCEL = 'abuse.linkIntercept.cancel',

  // ai.request.sent  - HTTP request is sent
  // ai.request.response - HTTP response / stream is finished
  // ai.request.streamStart - data is streamed and shown to user
  // ai.request.complete -interaction is completed
  // ai.request.error - error happened in response
  // ai.request.abort - user aborted such that ai.request.complete will neever happen
  AI_REQUEST_SENT = 'ai.request.sent',
  AI_REQUEST_ERROR = 'ai.request.error',
  AI_REQUEST_RETRY = 'ai.request.retry',
  AI_REQUEST_COMPLETE = 'ai.request.complete',
  AI_REQUEST_RESPONSE = 'ai.request.response',
  AI_REQUEST_ABORT = 'ai.request.abort',
  AI_REQUEST_STREAM_START = 'ai.request.streamStart',

  AI_SUGGESTION_APPLY = 'ai.suggestion.apply',
  AI_SUGGESTION_FEEDBACK = 'ai.suggestion.feedback.submit',

  AI_CARD_VARIATION_KEPT = 'ai.card.variation.kept',
  AI_CARD_TRANSLATION_KEPT = 'ai.card.translation.kept',
  AI_CARD_FEEDBACK = 'ai.card.feedback',
  AI_CARD_RATING = 'ai.card.rating',

  AI_CREDITS_DEDUCTED = 'ai.credits.deduct',
  AI_CREDITS_USED = 'ai.credits.use',

  CARD_VIEWED = 'card.view',
  CARD_CREATED = 'card.create',
  CARD_STYLES_UPDATED = 'card.styles.update',
  CARD_STYLES_RESET = 'card.styles.reset',
  DOC_ATTRIBUTES_UPDATED = 'doc.attrs.update',
  CARD_COLLAPSED = 'card.collapse',
  CARD_EXPANDED = 'card.expand',
  CARD_EXPORTED = 'card.export',
  CARD_LAYOUT_UPDATED = 'card.layout.update',

  COLOR_PICKER_OPENED = 'colorPicker.open',
  COLOR_PICKER_SET_COLOR = 'colorPicker.setColor',
  COLOR_PICKER_RESET = 'colorPicker.reset',

  DOC_VIEWED = 'doc.view',
  DOC_CREATED = 'doc.create',
  DOC_PRINTED = 'doc.print',
  DOC_ZOOMED = 'doc.zoom',
  DOC_THEME_UPDATED = 'doc.theme.update',
  DOC_SHARELINK_COPIED = 'doc.shareLink.copied',
  DOC_IMPORTED = 'doc.imported',
  DOC_IMPORT_FAILED = 'doc.import.failed',

  EXAMPLE_VIEWED = 'example.view',
  EXAMPLE_DUPLICATED = 'example.duplicate',
  MADE_WITH_GAMMA_CLICKED = 'madeWithGamma.click',
  EXAMPLE_SIGNUP_CLICKED = 'example.signup.click',

  HOME_TAB_VIEWED = 'homeTab.view',

  NODE_INSERTED = 'node.insert',

  // PRESENT MODE
  PRESENT_MODE_NAVIGATED = 'present.navigate',
  PRESENT_MODE_ENTERED = 'present.enter',
  PRESENT_MODE_EXITED = 'present.exit',

  // AUTH
  LOGIN = 'auth.login',
  SIGNUP = 'auth.signup',

  // MONETIZATION
  // These events were named for Pro, but they're actually for all products
  CHECKOUT_STARTED = 'monetization.products.pro.click',
  PURCHASE_COMPLETE = 'monetization.products.pro.purchase',

  CANCEL_SUBSCRIPTION_CLICK = 'monetization.cancelSubscription.click',
  CHURNKEY_FAILED_PAYMENT_WALL_SHOWN = 'monetization.churnkey.failedPaymentWall.shown',
  CHURNKEY_FAILED_PAYMENT_WALL_CLOSED = 'monetization.churnkey.failedPaymentWall.closed',
  CHURNKEY_CANCEL_SUBSCRIPTION_COMPLETE = 'monetization.churnkey.cancelSubscription.complete',
  CHURNKEY_HANDLE_SUPPORT_CLICK = 'monetization.churnkey.handleSupport.click',
  CHURNKEY_PAYMENT_METHOD_UPDATED = 'monetization.churnkey.paymentMethod.updated',

  GAMMA_PRO_BILLING_CYCLE_CLICK = 'monetization.products.pro.billingCycle.click',
  GAMMA_PRO_UPSELL_PRODUCTS_VIEWED = 'monetization.products.view',
  GAMMA_PRO_UPSELL_CALLOUT_AI_CREDITS = 'monetization.products.pro.upsell.aiCredits',
  GAMMA_UPSELL_CALLOUT_AI_CREDITS_CHAT = 'monetization.products.pro.upsell.aiCredits.chat',
  GAMMA_UPSELL_CALLOUT_AI_CREDITS_GENERATE_CARD = 'monetization.products.pro.upsell.aiCredits.generateCard',
  GAMMA_UPSELL_CALLOUT_AI_CREDITS_TRANSLATE_CARD = 'monetization.products.pro.upsell.aiCredits.translateCard',
  GAMMA_UPSELL_CALLOUT_AI_CREDITS_IMAGES = 'monetization.products.pro.upsell.aiCredits.images',
  GAMMA_UPSELL_CALLOUT_AI_CREDITS_GUIDED = 'monetization.products.pro.upsell.aiCredits.guided',
  GAMMA_UPSELL_CALLOUT_AI_CREDITS_ADVANCED = 'monetization.products.pro.upsell.aiCredits.advancedMode',
  GAMMA_UPSELL_CALLOUT_AI_CREDITS_GENERATE_V2 = 'monetization.products.pro.upsell.aiCredits.generatev2',
  GAMMA_UPSELL_CALLOUT_AI_CREDITS_GENERATE_CARD_NOTES = 'monetization.products.pro.upsell.aiCredits.generateCardNotes',
  GAMMA_UPSELL_CALLOUT_AI_CREDITS_PPT_IMPORT_V2 = 'monetization.products.pro.upsell.aiCredits.pptImportV2',
  GAMMA_UPSELL_CALLOUT_AI_GENERATE_LONGER = 'monetization.products.pro.upsell.aiGenerateLonger',
  GAMMA_UPSELL_CALLOUT_AI_IMAGE_MODELS = 'monetization.products.pro.upsell.aiImageModels',

  GAMMA_PRO_UPSELL_CALLOUT_WORKSPACE_SETTINGS = 'monetization.products.pro.upsell.workspaceSettings',
  GAMMA_PRO_UPSELL_CALLOUT_AI_MODEL_ADVANCED_MODE = 'monetization.products.pro.upsell.aiModelAdvancedMode',
  GAMMA_PRO_UPSELL_EXPORT_SETTINGS_REMOVE_WATERMARK = 'monetization.products.pro.upsell.exportSettingsRemoveWatermark',
  GAMMA_PRO_UPSELL_SITE_REMOVE_WATERMARK = 'monetization.products.pro.upsell.siteRemoveWatermark',
  GAMMA_PRO_UPSELL_PAGE_SETUP_REMOVE_WATERMARK = 'monetization.products.pro.upsell.pageSetupRemoveWatermark',
  GAMMA_PRO_UPSELL_SHARE_SETTINGS_REMOVE_WATERMARK = 'monetization.products.pro.upsell.shareSettingsRemoveWatermark',
  GAMMA_PRO_UPSELL_CALLOUT_CHANGE_HISTORY = 'monetization.products.pro.upsell.changeHistory',
  GAMMA_PRO_UPSELL_CALLOUT_SIDEBAR = 'monetization.products.pro.upsell.sidebar',
  GAMMA_PRO_UPSELL_CALLOUT_CUSTOM_FONTS = 'monetization.products.pro.upsell.customFonts',
  GAMMA_PRO_UPSELL_CALLOUT_EDIT_DOC_AI = 'monetization.products.pro.upsell.editDocAI',
  GAMMA_PRO_UPSELL_SUBSCRIPTION_EXPIRED_BUTTERBAR = 'monetization.products.pro.upsell.subscriptionExpiredButterbar',
  GAMMA_PRO_UPSELL_SUBSCRIPTION_INDIVIDUAL_ANALYTICS = 'monetization.products.pro.upsell.individualAnalytics',
  GAMMA_PRO_UPSELL_DOMAINS = 'monetization.products.pro.upsell.domains',
  GAMMA_PRO_UPSELL_REMOVE_BACKGROUND = 'monetization.products.pro.upsell.removeBackground',
  GAMMA_PRO_UPSELL_WORKSPACE_SETTINGS = 'monetization.products.pro.upsell.workspaceSettings',

  // ONBOARDING
  ONBOARDING_SIGNUP_COMPLETED = 'onboarding.signup.complete',
  WORKSPACE_CREATED = 'workspace.create',
  GET_STARTED_CLICKED = 'getStarted.click',

  // DOC ANALYTICS
  DOC_ANALYTICS_VIEWED = 'docAnalytics.view',

  // THEMES
  THEME_CREATED = 'theme.create',
  THEME_DUPLICATED = 'theme.duplicate',
  THEME_UPDATED = 'theme.update',
  THEME_IMPORTED = 'theme.import',
  THEME_IMPORT_FAILED = 'theme.import.failed',
  THEME_RATED = 'theme.rated',
  THEME_FEEDBACK = 'theme.feedback',

  // IN APP TEMPLATES
  IN_APP_TEMPLATE_THEME_CLICKED = 'inAppTemplate.theme.click',
  IN_APP_TEMPLATE_CREATE_THEME_CLICKED = 'inAppTemplate.createTheme.click',

  // PDFS
  PDF_EXPORTED = 'pdf.export',
  PNG_EXPORTED = 'png.export',
  PPTX_EXPORTED = 'pptx.export',

  // MARKETING_PAGES
  TEMPLATE_SEARCH = 'template.search',

  // GUIDERS
  DOC_EDITOR_TOUR_STARTED = 'docEditorTour.start',
  // The step at which they tried to bail
  DOC_EDITOR_TOUR_CLOSE_CLICKED = 'docEditorTour.close.click',
  // How far did they get in the tour?
  DOC_EDITOR_TOUR_COMPLETED = 'docEditorTour.completed',

  CREATE_FIRST_DOC_EXP_BUCKETED = 'createFirstDocExp.bucket',

  AI_DOC_RATED = 'ai.doc.rated',
  AI_DOC_FEEDBACK_SUBMITTED = 'ai.doc.feedback.submitted',
  AI_DOC_SHOWCASE_ALLOWED = 'ai.doc.showcase.allowed',
  AI_IMAGE_RATED = 'ai.image.rated',
  AI_IMAGE_FEEDBACK_SUBMITTED = 'ai.image.feedback.submitted',
  AI_IMPORT_SLIDE_RATED = 'ai.import-slide.rated',
  AI_IMPORT_SLIDE_FEEDBACK = 'ai.import-slide.feedback',

  // TOC
  TOC_PINNED_OPEN = 'toc.pinOpen',
  TOC_CARD_CLICKED = 'toc.card.click',
  TOC_CARD_DRAGGED = 'toc.card.drag',
  TOC_CARD_MENU_OPENED = 'toc.card.menu.open',

  // FILMSTRIP
  FILMSTRIP_TOGGLE_VIEW = 'filmstrip.toggle.view',

  // SITES
  DOC_COPIED_TO_SITE = 'site.doc.copiedToSite',
  DOC_MOVED_TO_SITE = 'site.doc.movedToSite',

  SITE_DOMAIN_PURCHASED = 'site.domain.purchased',
  SITE_DOMAIN_ADDED = 'site.domain.added',

  // EXPERIMENT BUCKETED
  EXPERIMENT_BUCKETED = 'experiment.bucketed',
}

export enum NodeInsertMethods {
  SLASH_MENU = 'slash_menu',
  BLOCK_PROMPT = 'block_prompt',
  INSERT_WIDGET = 'insert_widget',
  INPUT_RULE = 'input_rule',
  KEYBOARD_SHORTCUT = 'keyboard_shortcut',
  CARD_DIVIDER = 'card_divider',
  CARD_DIVIDER_DROPDOWN = 'card_divider_dropdown',
  CARD_DIVIDER_AI = 'card_divider_ai',
  FORMATTING_MENU = 'formatting_menu',
  SUMMARIZE = 'summarize',
  FILMSTRIP = 'filmstrip',
  FILMSTRIP_DROPDOWN = 'filmstrip_dropdown',
  FILMSTRIP_CARD_MENU = 'filmstrip_card_menu',
  CARD_TEMPLATE_MODAL = 'card_template_modal',
  CARD_TEMPLATE_PLACEHOLDER = 'card_template_placeholder',
}

export enum PresentNavigateMethods {
  KEYBOARD = 'keyboard',
  BUTTON_CLICK = 'button_click',
}

export enum PresentNavigateSource {
  MAIN_PRESENT_VIEW = 'main_present_view',
  PRESENTER_VIEW_POPUP = 'presenter_view_popup',
}

export enum PresentNavigateDirections {
  TELEPROMPTER_UP = 'teleprompter_up',
  TELEPROMPTER_DOWN = 'teleprompter_down',
  CARD_NEXT = 'card_next',
  CARD_PREV = 'card_prev',
}

export type DocCreatedSource =
  | 'example_deck_clone_toast'
  | 'import_google_doc'
  | 'import_google_slides'
  | 'import_docx'
  | 'import_pptx'
  | 'import_webpage'
  | 'import_pdf'
  | 'blank'
  | 'template_browser'
  | 'get_started_modal'
  | 'template_modal_griditem'
  | 'template_dashboard_griditem'
  | 'template_modal_preview'
  | 'template_modal_preview_create_theme'
  | 'slash-new'
  | 'editor_toolbar_doc_menu_duplicate'
  | 'dashboard_docs_view_duplicate'
  | 'ai_generated'
  | 'ai_generated_advanced'
  | 'sites_add_page'
  | 'sites_copy_to_site'
  | 'sites_duplicate_page'
