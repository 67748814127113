import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Theme } from 'modules/api'
import { RootState } from 'modules/redux'
import { ThemeFilterKey } from 'modules/theming/themePicker/types'
import { themeSortFunction } from 'modules/theming/utils/utils'

type ThemePickerState = {
  activeView?: 'standard' | 'custom' | 'search'
  themesToDisplay: Theme[]
  filterKeywords: ThemeFilterKey[]
  searchQuery: string
  globalThemes: Theme[]
  workspaceThemes: Theme[]
}

export const initialState: ThemePickerState = {
  activeView: undefined,
  themesToDisplay: [],
  filterKeywords: [],
  searchQuery: '',
  globalThemes: [],
  workspaceThemes: [],
}

const ThemePickerSlice = createSlice({
  name: 'ThemePicker',
  initialState,
  reducers: {
    reset: () => initialState,
    setActiveView(
      state: ThemePickerState,
      action: PayloadAction<{
        activeView: 'standard' | 'custom' | 'search' | undefined
      }>
    ) {
      state.activeView = action.payload.activeView
    },
    setWorkspaceThemes(
      state: ThemePickerState,
      action: PayloadAction<{ workspaceThemes: Theme[] }>
    ) {
      state.workspaceThemes = action.payload.workspaceThemes
    },
    setGlobalThemes(
      state: ThemePickerState,
      action: PayloadAction<{ globalThemes: Theme[] }>
    ) {
      state.globalThemes = action.payload.globalThemes
    },
    setFilterKeywords(
      state: ThemePickerState,
      action: PayloadAction<{ filterKeywords: ThemeFilterKey[] }>
    ) {
      state.filterKeywords = action.payload.filterKeywords
    },
    setSearchQuery(
      state: ThemePickerState,
      action: PayloadAction<{ searchQuery: string }>
    ) {
      state.searchQuery = action.payload.searchQuery
    },
    resetThemePickerFilters: (state: ThemePickerState) => {
      state.activeView = undefined
      state.themesToDisplay = []
      state.filterKeywords = []
      state.searchQuery = ''
    },
  },
})

export const {
  reset,
  setActiveView,
  setGlobalThemes,
  setWorkspaceThemes,
  setFilterKeywords,
  setSearchQuery,
  resetThemePickerFilters,
} = ThemePickerSlice.actions

type SliceState = Pick<RootState, 'ThemePicker'>

export const selectActiveView = (state: SliceState) =>
  state.ThemePicker.activeView

export const selectThemesToDisplay = (state: SliceState) => {
  const { globalThemes, workspaceThemes, searchQuery, filterKeywords } =
    state.ThemePicker

  if (state.ThemePicker.activeView === 'standard') {
    if (filterKeywords.length === 0) {
      return [...globalThemes].sort(themeSortFunction)
    }
    const andMatches = globalThemes.filter((theme) => {
      if (filterKeywords.length === 0) {
        return false
      }
      return filterKeywords.every((key) => {
        return (
          theme.config.keywords?.color.includes(key) ||
          theme.config.keywords?.tone.includes(key)
        )
      })
    })
    const orMatches = globalThemes
      .filter((theme) => {
        if (filterKeywords.length === 0) {
          return false
        }
        return filterKeywords.some((key) => {
          return (
            theme.config.keywords?.color.includes(key) ||
            theme.config.keywords?.tone.includes(key)
          )
        })
      })
      .filter((theme) => !andMatches.includes(theme))
    return [
      ...andMatches.sort(themeSortFunction),
      ...orMatches.sort(themeSortFunction),
    ]
  } else if (state.ThemePicker.activeView === 'custom') {
    return [...workspaceThemes].sort(themeSortFunction)
  }
  const lcSearchQuery = searchQuery?.toLowerCase()
  return [...globalThemes, ...workspaceThemes]
    .filter((theme) => {
      if (!searchQuery) {
        return true
      }
      return (
        theme.name.toLowerCase().includes(lcSearchQuery) ||
        theme.config.keywords?.color?.includes(lcSearchQuery) ||
        theme.config.keywords?.tone?.includes(lcSearchQuery) ||
        // Only search through display name if the theme is a workspace theme
        (theme.workspaceId &&
          theme.createdBy?.displayName?.toLowerCase().includes(lcSearchQuery))
      )
    })
    .sort(themeSortFunction)
}

export const selectFilterKeywords = (state: SliceState) =>
  state.ThemePicker.filterKeywords

export const selectSearchQuery = (state: SliceState) =>
  state.ThemePicker.searchQuery

export const selectHasWorkspaceThemes = (state: SliceState) =>
  state.ThemePicker.workspaceThemes.length > 0

export const selectGlobalThemes = (state: SliceState) =>
  state.ThemePicker.globalThemes

export const selectAllThemes = (state: SliceState) => [
  ...state.ThemePicker.globalThemes,
  ...state.ThemePicker.workspaceThemes,
]
export const ThemePickerReducer = ThemePickerSlice.reducer
