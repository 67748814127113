import { createSlice } from '@reduxjs/toolkit'

import {
  LEFT_PANEL_DEFAULT_WIDTH,
  RIGHT_PANEL_DEFAULT_WIDTH,
} from 'modules/panels/constants'
import { RootState } from 'modules/redux'

import { PanelComponent, PanelName, PanelPosition } from './types'

type SinglePanelState = {
  isOpen: boolean
  component: PanelComponent | null
  width: number
  disableTransition: boolean
}

export interface PanelState {
  left: SinglePanelState
  right: SinglePanelState
}

const initialState: PanelState = {
  left: {
    component: null,
    isOpen: false,
    width: LEFT_PANEL_DEFAULT_WIDTH,
    disableTransition: false,
  },
  right: {
    component: null,
    isOpen: false,
    width: RIGHT_PANEL_DEFAULT_WIDTH,
    disableTransition: false,
  },
}

const PanelsSlice = createSlice({
  name: 'Panels',
  initialState,
  reducers: {
    initializePanel(state, action) {
      const { component } = action.payload
      const position = component.panelPosition
      state[position].component = component
    },
    openPanel(
      state,
      action: {
        payload: { component: PanelComponent; disableTransition?: boolean }
      }
    ) {
      const { component, disableTransition } = action.payload
      const position = component.panelPosition
      state[position].component = component
      state[position].isOpen = true

      // close other panel
      position === 'left'
        ? (state['right'].isOpen = false)
        : (state['left'].isOpen = false)

      state[position].disableTransition = Boolean(disableTransition)
    },
    closePanel(state, action: { payload: { position: PanelPosition } }) {
      state[action.payload.position].isOpen = false
    },
    setPanelWidth(
      state,
      action: { payload: { position: PanelPosition; width: number } }
    ) {
      state[action.payload.position].width = action.payload.width
    },
    resetPanels: () => initialState,
  },
})

export const {
  closePanel,
  openPanel,
  setPanelWidth,
  resetPanels,
  initializePanel,
} = PanelsSlice.actions

type PanelsSliceState = Pick<RootState, 'Panels'>

// Selectors
export const selectIsPanelOpen =
  (position: PanelPosition) =>
  (state: PanelsSliceState): boolean => {
    return !!state.Panels[position].isOpen
  }

export const selectIsPanelNameOpen =
  (name: PanelName) =>
  (state: PanelsSliceState): boolean => {
    const leftPanel = state.Panels.left
    const rightPanel = state.Panels.right
    const leftPanelOpenAndMatches =
      leftPanel.component?.panelName === name && leftPanel.isOpen
    const rightPanelOpenAndMatches =
      rightPanel.component?.panelName === name && rightPanel.isOpen
    return leftPanelOpenAndMatches || rightPanelOpenAndMatches
  }

export const selectIsPanelComponentOpen =
  (component: PanelComponent) =>
  (state: PanelsSliceState): boolean => {
    const openComponent = state.Panels[component.panelPosition].component
    return (
      selectIsPanelOpen(component.panelPosition)(state) &&
      openComponent === component
    )
  }

export const selectPanelComponent =
  (position: PanelPosition) =>
  (state: PanelsSliceState): PanelComponent | null => {
    return state.Panels[position].component
  }

export const selectIsTransitionDisabled =
  (position: PanelPosition) =>
  (state: PanelsSliceState): boolean => {
    return state.Panels[position].disableTransition
  }

export const selectPanelWidth =
  (position: PanelPosition) =>
  (state: PanelsSliceState): number => {
    const isOpen = selectIsPanelOpen(position)(state)
    return isOpen ? state.Panels[position].width : 0
  }

// Reducer
export const PanelReducer = PanelsSlice.reducer
