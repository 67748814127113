import { DEFAULT_THEME_BACKGROUND } from 'modules/tiptap_editor/styles/constants'
import { BackgroundType } from 'modules/tiptap_editor/styles/types'
import { isColorDark } from 'utils/color'

import { Theme } from '../types'
import { getThemeCardColorSolid } from './colors'

export const themeSortFunction = (a: Theme, b: Theme) => {
  const regex = new RegExp(/\p{Emoji_Presentation}/, 'gu')
  const nameA = a.name.replace(regex, '').trim()
  const nameB = b.name.replace(regex, '').trim()

  return (
    // sort by priority first (global themes only), then sort by name (ignoring punctuation/emoji)
    (!a.workspaceId && !b.workspaceId ? b.priority - a.priority : 0) ||
    nameA.localeCompare(nameB, 'en-US', { ignorePunctuation: true })
  )
}

export const getThemeBackgroundOrDefault = (theme: Theme) =>
  theme.config?.background &&
  theme.config.background.type !== BackgroundType.NONE
    ? theme.config.background
    : DEFAULT_THEME_BACKGROUND

export const isThemeDark = (theme: Theme) => {
  const cardColor = getThemeCardColorSolid(theme)
  return cardColor
    ? isColorDark(cardColor)
    : theme.config.container?.isDark ?? false
}

// Get the first accent image, if any
export const getFirstAccentBackground = (theme: Theme) =>
  theme.config?.accentBackgrounds?.[0] ?? undefined

export const getThemeStyleDescription = (theme: Theme): string | undefined => {
  if (theme.config.stylePrompt) {
    return theme.config.stylePrompt
  }

  const keywords = theme.config.keywords
  if (!keywords) return

  const colorDescription = keywords.color.slice(0, 6).join(' ')
  const toneDescription = keywords.tone.slice(0, 6).join(' ')

  return `${colorDescription}, ${toneDescription}`
}
