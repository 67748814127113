import memoize from 'fast-memoize'
import { sortBy, uniqBy } from 'lodash'
import tinycolor from 'tinycolor2'

import { getBackgroundColor } from 'modules/tiptap_editor/styles/backgroundStyles'
import { DEFAULT_THEME_BACKGROUND } from 'modules/tiptap_editor/styles/constants'
import {
  BackgroundOptions,
  BackgroundType,
} from 'modules/tiptap_editor/styles/types'
import {
  brightenColor,
  colorWithLightness,
  getColorLightness,
  isColorDark,
  makeColorReadable,
  saturateColor,
} from 'utils/color'

import {
  DEFAULT_ACCENT_COLOR,
  DEFAULT_BODY_COLOR,
  DEFAULT_BODY_COLOR_DARK,
  DEFAULT_CARD_BORDER_COLOR,
  DEFAULT_CARD_BORDER_COLOR_DARK,
  DEFAULT_CONTRAST_RATIO,
  DEFAULT_DARK_CONTAINER_BACKGROUND,
  DEFAULT_HEADING_COLOR,
  DEFAULT_HEADING_COLOR_DARK,
  DEFAULT_LIGHT_CONTAINER_BACKGROUND,
} from '../constants'
import { getBorderColorForBackground } from '../styles/shapeColor'
import {
  Theme,
  ThemeColor,
  ThemeColorGradient,
  ThemeColorSolid,
  ThemeKnobsV3,
} from '../types'
import { isThemeGlassy } from './design'
import {
  getGradientCss,
  getGradientFallbackColor,
  makeGradientReadable,
  mapLegacyLinearGradient,
} from './gradient'
import { isThemeDark } from './utils'

export const getThemeColorSolid = memoize(
  (color: ThemeColor | string): string => {
    if (typeof color === 'string') {
      return color
    }
    if (color.type === 'linear-gradient') {
      return getGradientFallbackColor(color)
    }
    return color.color || DEFAULT_BODY_COLOR
  }
)

export const forceThemeColorSolid = (color: ThemeColor): ThemeColorSolid => {
  if (color.type === 'solid') {
    return color
  }
  return {
    type: 'solid',
    color: getThemeColorSolid(color),
  }
}

export const isThemeColorDark = memoize((color: ThemeColor): boolean => {
  return isColorDark(getThemeColorSolid(color))
})

export const getThemeAccentColors = memoize((theme: Theme): ThemeColor[] => {
  const primary = getThemePrimaryAccentColor(theme)
  const secondary = getThemeSecondaryAccentColors(theme)
  return [primary, ...secondary]
})

export const getThemeSecondaryAccentColors = memoize(
  (theme: Theme): ThemeColor[] => {
    if (theme.config.secondaryColors) {
      return theme.config.secondaryColors
    }

    return (
      theme.config.secondaryAccentColors
        ?.filter(Boolean)
        .map<ThemeColor>((color: string) => ({
          type: 'solid',
          color: color,
        })) || []
    )
  }
)

export const getThemePrimaryAccentColor = memoize(
  (theme: Theme): ThemeColor => {
    const { primaryColor, accentGradient } = theme.config
    if (primaryColor) {
      return primaryColor
    }

    // Pre-V3 used theme.config.accentGradient or theme.accentColor
    if (
      accentGradient &&
      !accentGradient.disabled &&
      accentGradient.colors.length >= 2
    ) {
      return mapLegacyLinearGradient(accentGradient)
    }

    const accentColor = theme.accentColor ?? DEFAULT_ACCENT_COLOR
    return {
      type: 'solid',
      color: accentColor,
    }
  }
)

export const getThemeShapeColors = memoize(
  (theme: Theme): ThemeColorSolid[] => {
    const { shapeColors } = theme.config
    if (shapeColors && shapeColors.length > 0) {
      return shapeColors
    }
    return getThemeAccentColors(theme).map((c) => ({
      type: 'solid',
      color: getThemeColorSolid(c),
    }))
  }
)

export const getThemeShapeBackgroundColor = memoize(
  (
    theme: Theme,
    shapeColorScheme: ThemeKnobsV3['shapeColorScheme']
  ): ThemeColorSolid => {
    const shapeColors = getThemeShapeColors(theme)
    const cardColor = getThemeColorSolid(getThemeCardColor(theme))
    const accentColor = getThemeColorSolid(getThemePrimaryAccentColor(theme))
    const isDark = isColorDark(cardColor)
    const backgroundLightness = getColorLightness(cardColor)
    const shapeBackgroundColor =
      // Default color scheme will lighten or darken the card color
      shapeColorScheme === 'default'
        ? saturateColor(
            brightenColor(cardColor, backgroundLightness > 0.25 ? -6 : 12),
            backgroundLightness > 0.5 ? 15 : 0
          )
        : // Accent color scheme will lighten or darken the accent color
        shapeColorScheme === 'accent'
        ? isDark
          ? colorWithLightness(accentColor, 0.25)
          : colorWithLightness(accentColor, 0.9)
        : getThemeColorSolid(shapeColors[0]) // todo: handle gradients
    return {
      type: 'solid',
      color: shapeBackgroundColor,
    }
  }
)

export const getThemeShapeBorderColor = memoize(
  (
    theme: Theme,
    shapeColorScheme: ThemeKnobsV3['shapeColorScheme'],
    shapeFill: ThemeKnobsV3['shapeFill']
  ): string => {
    if (shapeFill === 'none' && shapeColorScheme !== 'default') {
      return getThemeColorSolid(
        getThemeShapeBackgroundColor(theme, shapeColorScheme)
      )
    }

    return getBorderColorForBackground(
      getThemeColorSolid(getThemeShapeBackgroundColor(theme, shapeColorScheme))
    )
  }
)

export const getThemeLinkColor = memoize((theme: Theme): ThemeColorSolid => {
  const { linkColor } = theme.config
  return (
    linkColor || {
      type: 'solid',
      color: getThemeColorSolid(getThemePrimaryAccentColor(theme)),
    }
  )
})

export const getThemeButtonColor = memoize((theme: Theme): ThemeColor => {
  const { buttonColor } = theme.config
  return buttonColor || getThemePrimaryAccentColor(theme)
})

export const getThemeBodyColor = memoize((theme: Theme): ThemeColorSolid => {
  const { bodyColor } = theme.config

  // If no body color is set, use the default
  if (!bodyColor) {
    return {
      type: 'solid',
      color: isThemeDark(theme) ? DEFAULT_BODY_COLOR_DARK : DEFAULT_BODY_COLOR,
    }
  }

  // If body color is a string, convert to ThemeColor
  if (typeof bodyColor === 'string') {
    return {
      type: 'solid',
      color: bodyColor,
    }
  }

  return bodyColor
})

export const getThemeBodyColorSolid = (theme: Theme): string => {
  return getThemeColorSolid(getThemeBodyColor(theme))
}

export const getThemeCardColor = memoize((theme: Theme): ThemeColorSolid => {
  const { cardBackground, cardColor, container } = theme.config
  // New card color as of v3
  if (cardColor) {
    return cardColor
  }

  // Themes v2 background colors
  const bgColor = getBackgroundColor(cardBackground || container?.background)
  if (bgColor) {
    return {
      type: 'solid',
      color: bgColor,
    }
  }

  // Themes v1 just had isDark setting
  return {
    type: 'solid',
    color: container?.isDark
      ? DEFAULT_DARK_CONTAINER_BACKGROUND
      : DEFAULT_LIGHT_CONTAINER_BACKGROUND,
  }
})

export const getThemeCardBorderColor = memoize(
  (theme: Theme): ThemeColorSolid => {
    const { config } = theme
    const isDark = isThemeDark(theme)
    if (typeof config.cardBorderColor === 'string') {
      return {
        type: 'solid',
        color: config.cardBorderColor,
      }
    } else if (config.cardBorderColor) {
      return config.cardBorderColor
    }

    const isGlassy = isThemeGlassy(config)
    if (isGlassy) {
      return {
        type: 'solid',
        color: isDark
          ? 'rgba(230, 230, 230, 0.25)'
          : 'rgba(230, 230, 230, 0.75)',
      }
    }

    return {
      type: 'solid',
      color: isDark
        ? DEFAULT_CARD_BORDER_COLOR_DARK
        : DEFAULT_CARD_BORDER_COLOR,
    }
  }
)

export const getThemeCardColorSolid = (theme: Theme): string => {
  return getThemeColorSolid(getThemeCardColor(theme))
}

export const getThemeHeadingColor = memoize((theme: Theme): ThemeColor => {
  const { headingColor, headingGradient } = theme.config

  if (isThemeColor(headingColor)) {
    return headingColor
  }

  // If we don't have a ThemeColor, we need to check the headingGradient prop
  if (
    headingGradient &&
    !headingGradient.disabled &&
    headingGradient.colors.length >= 2
  ) {
    return mapLegacyLinearGradient(headingGradient)
  }

  // If headingColor is a string, convert to ThemeColor
  if (typeof headingColor === 'string') {
    return {
      type: 'solid',
      color: headingColor,
    }
  }

  // If no color is set, use the default
  return {
    type: 'solid',
    color: isThemeDark(theme)
      ? DEFAULT_HEADING_COLOR_DARK
      : DEFAULT_HEADING_COLOR,
  }
})

export const makeThemeColorReadable = memoize(
  (
    color: ThemeColor,
    contrast: string,
    contrastRatio: number = DEFAULT_CONTRAST_RATIO,
    whiteBlackOnly: boolean = false
  ): ThemeColor => {
    if (color.type === 'linear-gradient') {
      return makeGradientReadable(
        color,
        contrast,
        contrastRatio,
        whiteBlackOnly
      )
    }
    return {
      type: 'solid',
      color: makeColorReadable(
        color.color,
        contrast,
        contrastRatio,
        whiteBlackOnly
      ),
    }
  }
)

export const getThemePalette = memoize(
  (
    theme: Theme,
    solidOnly?: boolean,
    allowDuplicates?: boolean
  ): ThemeColor[] => {
    // todo: whats the right ordering?
    const inputs = [
      ...getThemeAccentColors(theme),
      getThemeBodyColor(theme),
      getThemeHeadingColor(theme),
      getThemeCardColor(theme),
      theme.config.linkColor,
      theme.config.buttonColor,
      ...(theme.config.shapeColorScheme === 'custom' && theme.config.shapeColors
        ? theme.config.shapeColors
        : []),
    ].filter(solidOnly ? isThemeColorSolid : isThemeColor)

    if (allowDuplicates) {
      return inputs
    }

    return uniqBy(inputs, themeColorComparator)
  }
)

export const themeColorComparator = memoize((color: ThemeColor) => {
  if (color.type === 'solid') {
    return tinycolor(color.color).toHexString()
  }
  return JSON.stringify(color)
})

export const checkThemeColorsEqual = (
  color1: ThemeColor,
  color2: ThemeColor
): boolean => {
  return themeColorComparator(color1) === themeColorComparator(color2)
}

export const getThemeColorBackgroundCss = memoize((color: ThemeColor) => {
  if (color.type === 'linear-gradient') {
    return {
      background: getGradientCss(color),
    }
  }

  return {
    backgroundColor: color.color,
  }
})

export const getThemeColorTextCss = memoize((color: ThemeColor) => {
  if (color.type === 'linear-gradient') {
    return {
      background: getGradientCss(color),
      backgroundClip: 'text',
      caretColor: getGradientFallbackColor(color),
      color: 'transparent',
    }
  }

  return {
    color: color.color,
  }
})

export const getThemeDocBackgroundColor = memoize(
  (theme: Theme): ThemeColorSolid => {
    const { background } = theme.config
    if (!background) {
      return {
        type: 'solid',
        color: DEFAULT_THEME_BACKGROUND.color.hex,
      }
    }

    if (background?.type === 'color' && background.color) {
      return {
        type: 'solid',
        color: background.color.hex,
      }
    } else if (
      background?.type === 'image' &&
      background.image &&
      background.image.meta?.average_color
    ) {
      return {
        type: 'solid',
        color: background.image.meta.average_color,
      }
    } else {
      return {
        type: 'solid',
        color: DEFAULT_THEME_BACKGROUND.color.hex,
      }
    }
  }
)

export const getThemeColorLabel = memoize((color: ThemeColor) => {
  if (color.type === 'linear-gradient') {
    return sortBy(color.stops, 'position')
      .map(
        (c) =>
          `${tinycolor(c.color).toHexString().toUpperCase()} ${c.position}%`
      )
      .join(', ')
  }
  return tinycolor(color.color).toHexString().toUpperCase()
})

export const isThemeColor = (
  color: ThemeColor | string | undefined
): color is ThemeColor => {
  return typeof color === 'object' && 'type' in color
}

export const isThemeColorSolid = (
  color: ThemeColor | string | undefined
): color is ThemeColorSolid => {
  return isThemeColor(color) && color.type === 'solid'
}

export const isThemeColorGradient = (
  color: ThemeColor | string | undefined
): color is ThemeColorGradient => {
  return isThemeColor(color) && color.type === 'linear-gradient'
}

export function getLegacyBackgroundColor(
  color: ThemeColorSolid
): BackgroundOptions
export function getLegacyBackgroundColor(
  color: ThemeColor | undefined
): BackgroundOptions | undefined {
  if (!color) {
    return undefined
  }
  if (color.type === 'solid') {
    return {
      type: BackgroundType.COLOR,
      color: {
        hex: color.color,
      },
    }
  }
  return undefined
}
