/**
 * This is a replacement for localforage that doesnt  stringify
 *
 * This was causing issues with large strings being stored in indexeddb
 * and causing weird race conditions
 */
export class IndexedDBStorage<T> {
  private db: IDBDatabase | null = null
  private initPromise: Promise<void>

  constructor(
    private readonly dbName: string = 'ApolloCache',
    private readonly storeName: string = 'data'
  ) {
    this.initPromise = this.initDB()
  }

  private async initDB(): Promise<void> {
    if (!this.isIndexedDBAvailable()) {
      console.warn(
        'IndexedDB is not available. Falling back to no-op implementation.'
      )
      return
    }

    return new Promise((resolve, reject) => {
      const request = indexedDB.open(this.dbName, 1)
      request.onerror = () => {
        console.error('Error opening IndexedDB')
        reject()
      }
      request.onsuccess = (event) => {
        this.db = (event.target as IDBOpenDBRequest).result
        resolve()
      }
      request.onupgradeneeded = (event) => {
        const db = (event.target as IDBOpenDBRequest).result
        db.createObjectStore(this.storeName)
      }
    })
  }

  private isIndexedDBAvailable(): boolean {
    return typeof indexedDB !== 'undefined'
  }

  private async getObjectStore(
    mode: IDBTransactionMode
  ): Promise<IDBObjectStore | null> {
    await this.initPromise
    if (!this.db) return null
    const transaction = this.db.transaction([this.storeName], mode)
    return transaction.objectStore(this.storeName)
  }

  async getItem(key: string): Promise<T | null> {
    if (!this.isIndexedDBAvailable()) return null
    const store = await this.getObjectStore('readonly')
    if (!store) return null

    return new Promise((resolve) => {
      const request = store.get(key)
      request.onsuccess = () => resolve(request.result)
      request.onerror = () => resolve(null)
    })
  }

  async setItem(key: string, value: T): Promise<void> {
    if (!this.isIndexedDBAvailable()) return
    const store = await this.getObjectStore('readwrite')
    if (!store) return

    return new Promise((resolve, reject) => {
      const request = store.put(value, key)
      request.onsuccess = () => resolve()
      request.onerror = () => reject()
    })
  }

  async removeItem(key: string): Promise<void> {
    if (!this.isIndexedDBAvailable()) return
    const store = await this.getObjectStore('readwrite')
    if (!store) return

    return new Promise((resolve) => {
      const request = store.delete(key)
      request.onsuccess = () => resolve()
      request.onerror = () => resolve()
    })
  }
}
