import { useRouter } from 'next/router'
import { useEffect } from 'react'

import { replaceState } from 'modules/history/history'
const stripQueryParamsAndReplaceState = ({
  queryKey,
}: {
  queryKey: string | string[]
}) => {
  if (!queryKey) {
    return // noop
  }
  const { searchParams } = new URL(window.location.href)
  if (Array.isArray(queryKey)) {
    queryKey.forEach((key) => {
      searchParams.delete(key)
    })
  } else {
    searchParams.delete(queryKey)
  }
  const query = Object.fromEntries(searchParams.entries())
  replaceState({ query })
}

export const useStripQueryParams = ({
  queryKey,
}: {
  queryKey: string | string[]
}) => {
  const { isReady: isRouterReady } = useRouter()

  useEffect(() => {
    if (!isRouterReady) {
      return
    }

    stripQueryParamsAndReplaceState({ queryKey })
  }, [isRouterReady, queryKey])
}
