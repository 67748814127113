import { ContainerEffect } from 'modules/tiptap_editor/styles/types'

import { LEGACY_THEME_BASE_KNOBS, THEME_KNOB_DEFAULTS } from '../constants'
import { Theme, ThemeKnobsV3 } from '../types'

export const isThemeGlassy = (config: Theme['config']) => {
  if (config.cardTransparency) {
    return (
      config.cardTransparency === 'frosted' ||
      config.cardTransparency === 'faded'
    )
  }
  return config.themeBase === 'glass' || config.themeBase === 'daydream'
}

export const getThemeKnobsV3 = (theme: Theme): ThemeKnobsV3 => {
  let { themeBase } = theme.config

  if (!themeBase) {
    if (
      theme?.config.container?.effect === ContainerEffect.FROSTED ||
      theme?.config.container?.effect === ContainerEffect.FADED
    ) {
      // Legacy custom themes don't have a base defined, so
      // we default based on the card style
      themeBase = 'glass'
    } else {
      themeBase = 'default'
    }
  }

  const baseDefaults = themeBase
    ? LEGACY_THEME_BASE_KNOBS[themeBase]
    : undefined
  return {
    ...THEME_KNOB_DEFAULTS,
    ...baseDefaults,
    ...theme.config,
  }
}

export const getThemeLogoSrc = (theme?: Theme | null) => {
  if (!theme) {
    return null
  }

  if (theme.config.logoImage) {
    return theme.config.logoImage.src || null
  }

  return theme.logoUrl || null
}
