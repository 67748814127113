import { configureStore } from '@reduxjs/toolkit'
import { isEqual, memoize } from 'lodash'
import {
  TypedUseSelectorHook,
  useDispatch,
  useSelector,
  useStore,
} from 'react-redux'

import { AIGeneratorReducer } from 'modules/ai/generator/reducer'
import { ImportPPTReducer } from 'modules/ai/importPpt/reducer'
import { AIStreamReducer } from 'modules/ai/stream/reducer'
import { AIVariationsReducer } from 'modules/ai/variations/reducer'
import { reducer as CardNotesReducer } from 'modules/card_notes/reducer'
import { CardsReducer, reset as ResetCards } from 'modules/cards/reducer'
import { ConnectionReducer } from 'modules/connection/reducer'
import { CreditsReducer } from 'modules/credits/reducer'
import { reducer as FeatureFlagsReducer } from 'modules/featureFlags/reducer'
import { reducer as FilmstripReducer } from 'modules/filmstrip/reducer'
import { I18nReducer } from 'modules/i18n/reducer'
import { reducer as IntercomReducer } from 'modules/intercom/reducer'
import { reducer as ModalStateReducer } from 'modules/modal_state/reducer'
import { OfflineManagerReducer } from 'modules/offline/manager/reducer'
import { PanelReducer } from 'modules/panels/reducer'
import { PreviewReducer } from 'modules/preview/reducer'
import { reducer as SearchModalReducer } from 'modules/search/reducer'
import { SiteReducer } from 'modules/sites/reducer'
import { ThemeEditingReducer } from 'modules/theming/ThemeConfiguration/themeEditing/reducer'
import { ThemeImporterReducer } from 'modules/theming/ThemeConfiguration/ThemeImporter/reducer'
import { ThemePickerReducer } from 'modules/theming/themePicker/reducer'
import { MediaDrawerReducer } from 'modules/tiptap_editor/components/drawers/MediaDrawer/reducer'
import { CardMenusReducer } from 'modules/tiptap_editor/extensions/Card/Card2/CardStylingMenu/reducer'
import { EditBackgroundDrawerReducer } from 'modules/tiptap_editor/extensions/Card/Card2/EditBackgroundDrawer/reducer'
import { reducer as ImageEditorReducer } from 'modules/tiptap_editor/extensions/media/Image/reducer'
import {
  reset as ResetTiptap,
  reducer as TipTapReducer,
} from 'modules/tiptap_editor/reducer'
import { UserReducer } from 'modules/user/reducer'
import { reducer as DesignPartnerAnalyticsReducer } from 'sections/docs/editor/components/DesignPartner/analytics/designPartnerAnalyticsReducer'
import { reducer as DesignPartnerReducer } from 'sections/docs/editor/components/DesignPartner/reducer'
import { reducer as FilmstripDragAndDropReducer } from 'sections/docs/editor/components/TableOfContents/Filmstrip/dragAndDrop/reducer'
import {
  reducer as DocEditorReducer,
  reset as ResetDocEditor,
} from 'sections/docs/reducer'
import { reducer as HomeReducer } from 'sections/home_v2/reducer'

export const moduleReducers = {
  AIGenerator: AIGeneratorReducer,
  AIStream: AIStreamReducer,
  AIVariations: AIVariationsReducer,
  Cards: CardsReducer,
  CardNotes: CardNotesReducer,
  Credits: CreditsReducer,
  DesignPartner: DesignPartnerReducer,
  ImageEditor: ImageEditorReducer,
  I18n: I18nReducer,
  TipTap: TipTapReducer,
  Panels: PanelReducer,
  CardMenus: CardMenusReducer,
  SearchModal: SearchModalReducer,
  Intercom: IntercomReducer,
  ModalState: ModalStateReducer,
  DesignPartnerAnalytics: DesignPartnerAnalyticsReducer,
  Filmstrip: FilmstripReducer,
  FilmstripDragAndDrop: FilmstripDragAndDropReducer,
  FeatureFlags: FeatureFlagsReducer,
  User: UserReducer,
  Site: SiteReducer,
  Preview: PreviewReducer,
  ThemePicker: ThemePickerReducer,
  Home: HomeReducer,
  EditBackgroundDrawer: EditBackgroundDrawerReducer,
  MediaDrawer: MediaDrawerReducer,
  ImportPPT: ImportPPTReducer,
  Connection: ConnectionReducer,
  ThemeImporter: ThemeImporterReducer,
  ThemeEditing: ThemeEditingReducer,
  OfflineManager: OfflineManagerReducer,
} as const

const rootReducer = {
  // Sections/Pages
  DocEditor: DocEditorReducer,

  // Modules
  ...moduleReducers,
}

// TODO - Is there a more elegant way to reset all the stores?
// This action is necessary to reset the state when running in an SSR (lambda) environment
export const globalResetAction = () => {
  const dispatch = getStore().dispatch
  dispatch(ResetCards())
  dispatch(ResetDocEditor())
  dispatch(ResetTiptap())
}

export const getStore = memoize(() => {
  // All stores must be registered here.
  // Code splitting ideas: https://redux.js.org/recipes/code-splitting
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      // dont enable serializeableCheck since we do not use any features requiring this,
      // such as time travelling debugging, persisting the store state or replaying of actions
      getDefaultMiddleware({ serializableCheck: false }),
  })
})

if (process.env.NODE_ENV !== 'production' && module.hot) {
  // Handle hot reloading by replacing the reducers:
  // See https://redux.js.org/usage/configuring-your-store#hot-reloading
  // @ts-ignore
  const replaceFn = () => getStore().replaceReducer(rootReducer)
  module.hot.accept('modules/tiptap_editor/reducer', replaceFn)
  module.hot.accept('modules/panels/reducer', replaceFn)
  module.hot.accept('sections/docs/reducer', replaceFn)
  console.log(
    '%c $$$$$$$$$$$$$$$$$$$$ Redux HMR replaceReducer $$$$$$$$$$$$$$$$$$$$',
    'background-color: #764abc; color: white;'
  )
}

type StoreType = ReturnType<typeof getStore>
export type RootState = ReturnType<StoreType['getState']>
export type AppDispatch = StoreType['dispatch']
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
export const useAppStore = () => useStore<RootState>()

/**
 * Utility to observe a selector outside of React context.
 * Inspired by https://github.com/reduxjs/redux/issues/303#issuecomment-125184409
 */
export function observeSelector<T>(
  select: (state: RootState) => T,
  onChange: (val: T) => void,
  store?: StoreType
) {
  const storeToUse = store || getStore()
  let currentState: T

  function handleChange() {
    const nextState = select(storeToUse.getState())
    if (!isEqual(nextState, currentState)) {
      currentState = nextState
      onChange(currentState)
    }
  }

  const unsubscribe = storeToUse.subscribe(handleChange)
  handleChange()
  return unsubscribe
}
